import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ee4854c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-main-layout" }
const _hoisted_2 = { class: "app-main-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, {
    size: "large",
    spinning: _ctx.loading,
    delay: 300
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AppHeader, { abort: _ctx.abort }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "header", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["abort"]),
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ])
    ]),
    _: 3
  }, 8, ["spinning"]))
}