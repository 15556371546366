
import { defineComponent, PropType } from 'vue'

import { TableColumnsType } from 'ant-design-vue'

import { IPagination } from './types'

export default defineComponent({
  name: 'TableList',
  props: {
    search: {
      type: String as PropType<string>,
      default: ''
    },
    columns: {
      type: Array as PropType<TableColumnsType>,
      required: true
    },
    dataSource: {
      type: Array as PropType<any[]>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    pagination: {
      type: Object as PropType<IPagination>
    },
    linkCallback: {
      type: Function as PropType<
        (id: number, is_read?: boolean | undefined) => void
      >
    }
  },
  emits: ['update:search'],
  setup(props, { emit }) {
    const rowClassName = (record: any) => {
      return record.is_read ? 'haveread' : 'unread'
    }

    const routerLink = (id: number, is_read: boolean | undefined): void => {
      props.linkCallback?.(id, is_read)
    }

    const searchInput = (e: InputEvent) => {
      const value = (e.target as HTMLInputElement).value

      emit('update:search', value)
    }

    return {
      rowClassName,
      routerLink,
      searchInput
    }
  }
})
