
import { defineComponent, computed, PropType, createVNode } from 'vue'

import { useRoute, useRouter } from 'vue-router'

import { Modal, message } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

import { clearStorage } from '@/utils/storage'

export default defineComponent({
  name: 'AppHearder',
  props: {
    abort: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup() {
    const router = useRouter()

    const route = useRoute()

    const title = computed(() => route.meta.title)

    const routerLink = () => {
      router.push('/archive')
    }

    const signOut = () => {
      Modal.confirm({
        title: '您确定要退出当前登录账号吗?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          { style: 'color:red;' },
          '点击确定退出登录'
        ),
        onOk() {
          clearStorage()
          message.success('退出登录成功', 2, () => {
            router.replace('/login')
          })
        },
        class: 'test'
      })
    }

    return {
      title,
      routerLink,
      signOut
    }
  }
})
