
import { defineComponent, PropType } from 'vue'

import AppHeader from '../Header/index.vue'

export default defineComponent({
  name: 'AppLayout',
  props: {
    abort: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  components: {
    AppHeader
  }
})
